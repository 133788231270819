// About.js
import React from 'react';
import Image1 from '../images/February/February-1.png';
import Image2 from '../images/February/February-2.png';

function Newsletter() {
    return (
        <div className="About">
            <img src={Image1} alt="About" className="About-image" />
            <img src={Image2} alt="About" className="About-image" />

        </div>
    );
}

export default Newsletter;
